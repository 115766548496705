// src/components/Layout.js
import React from 'react';
import '../App.css';
import Navigation from './Navigation';
import logotyp from "../images/logotyp.png"

const Layout = ({ children }) => {
    return (
        <div>
            {/* Header */}
            <header alt="privat logoped göteborg">
                
                <img src={logotyp} alt="logotyp" id='header-logo'/>
            </header>

            {/* Navigation */}
            <Navigation />

            {/* Main Content */}
            <main>{children}</main>

            
        </div>
    );
};

export default Layout;
