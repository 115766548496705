import React, { useState } from "react";
import Layout from "./Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faHands } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  const [selectedModule, setSelectedModule] = useState(null);

  return (
    <Layout>
      <div className="main-div">
        {selectedModule === null && (
          <AllServices setSelectedModule={setSelectedModule} />
        )}
        {selectedModule === "behaviour" && (
          <BehaviourModule setSelectedModule={setSelectedModule} />
        )}
        {selectedModule === "eating" && (
          <EatingModule setSelectedModule={setSelectedModule} />
        )}
        {selectedModule === "communication" && (
          <CommunicationModule setSelectedModule={setSelectedModule} />
        )}
      </div>
    </Layout>
  );
};

const AllServices = ({ setSelectedModule }) => {
  const communication = () => {
    setSelectedModule("communication");
  };
  const eating = () => {
    setSelectedModule("eating");
  };
  const behaviour = () => {
    setSelectedModule("behaviour");
  };

  return (
    <div>
      <h2>VÅRA TJÄNSTER</h2>
      <p className="under-title">
        Nedan kan du läsa mer om de olika områden där vi kan erbjuda stöd och
        utbildning.
      </p>
      <div className="services-container">
        <div className="service" onClick={communication}>
          KOMMUNIKATION OCH AKK
          <FontAwesomeIcon icon={faHands} id="service-icon" />
        </div>
        <div className="service" onClick={eating}>
          ÄTANDE <FontAwesomeIcon icon={faUtensils} id="service-icon" />
        </div>
        <div className="service" onClick={behaviour}>
          BETEENDE
          <FontAwesomeIcon icon={faHandHoldingHeart} id="service-icon" />
        </div>
      </div>
    </div>
  );
};

const CommunicationModule = ({ setSelectedModule }) => {
  return (
    <div>
      <button className="back" onClick={() => setSelectedModule(null)}>
        <FontAwesomeIcon icon={faArrowLeftLong} id="arrow" />
        TILLBAKA
      </button>
      <div className="module">
        <p className="module-title">KOMMUNIKATION OCH AKK</p>
        <FontAwesomeIcon icon={faHands} id="hands" />
        <p className="main-text">
          Som logopeder träffar vi ofta barn, ungdomar eller vuxna som av olika
          anledningar har svårigheter med att kommunicera. Det kan handla om
          svårigheter att förstå när andra pratar eller att själv uttrycka sig,
          svårigheter med talet eller att samspela. Det kan vara barn som är
          försenade i sin språk- och talutveckling, ett barn eller vuxen som har
          en funktionsnedsättning som påverkar sättet att kommunicera eller en
          person som på grund av sjukdom eller skada fått svårigheter senare i
          livet.
          <br />
          <br />
          Ibland behöver någon typ av AKK (Alternativ och Kompletterande
          Kommunikation) finnas tillgängligt för att en person ska kunna
          kommunicera med sin omgivning. Att inte kunna förstå det som andra
          säger, eller att inte själv kunna uttrcka sig skapar ofta frustration
          och oro hos personen. <br />
          <br />
          <h2 className="module-h2">VI ERBJUDER</h2>
          <h3 className="module-h3">Individuella insatser:</h3>
          <ul className="service-list">
            <li>
              Kartläggning/bedömning av språk, kommunikationsförmåga, samspel
            </li>
            <li>
              Behandlingsinsatser kring språkliga/kommunikativa svårigheter
            </li>
            <li>Kartläggning kring behov av AKK samt utprovning av AKK</li>
            <li>Stöd kring användning av AKK i vardagen</li>
          </ul>
          <h3 className="module-h3">
            Utbildning, föreläsning och handledning till föräldrar, närstående
            eller personal kring:
          </h3>
          <ul className="service-list">
            <li>
              Kommunikation och kommunikationsutveckling (går även att få
              kopplat till olika/specifika diagnoser som exempelvis Cerebral
              pares, Downs syndrom, språkstörning eller
              flerfunktionsnedsättning)
            </li>
            <li>Strategier för att stötta och stimulera kommunikationen</li>
            <li>
              AKK, som exempelvis bildstöd, TAKK (Tecken som AKK) eller olika
              pratapparater och appar för kommunikation.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

const EatingModule = ({ setSelectedModule }) => {
  return (
    <div>
      <button className="back" onClick={() => setSelectedModule(null)}>
        <FontAwesomeIcon icon={faArrowLeftLong} id="arrow" />
        TILLBAKA
      </button>
      <div className="module">
        <p className="module-title">ÄTANDE OCH ÄTSVÅRIGHETER</p>
        <FontAwesomeIcon icon={faUtensils} id="eating" />
        <p className="main-text">
          Ätsvårigheter kan ha olika orsaker och yttra sig på olika sätt. Allt
          ifrån att det lilla barnet kan ha svårigheter att suga och svälja,
          komma igång med smakportioner eller komma vidare till fastare
          konsistenser, till att en person kan ha oralmotoriska svårigheter,
          svårt att tugga och svälja, eller ha ett selektivt ätande. Många barn
          har också en period i förskoleåldern där de är petiga med maten.
          Ibland är tillväxten påverkad och ibland inte, ibland visar personen
          tydligt obehag kopplat till ätandet, men inte alltid. Det som dock
          ofta är gemensamt oavsett orsak, är att ätandet och måltiderna skapar
          stress och oro både hos personen med svårigheter och hos omgivningen.{" "}
          <br />
          <br />
          Vi har stor erfarenhet av att träffa barn med ätsvårigheter och deras
          familjer, och har genom kliniskt arbete och flera vidareutbildningar
          fördjupat oss inom området. Vi brinner för att jobba med ätande och
          ätsvårigheter av olika karaktär. Många föräldrar vi träffar
          efterfrågar kunskap om ätutveckling och ätsvårigheter samt hur de kan
          göra för att stötta sitt barn. Många upplever också att det är svårt
          att veta vart de ska vända sig för att få hjälp när deras barn inte
          äter och de flesta uttrycker att de hade önskat att de fått mer stöd
          tidigare.
          <br />
          <br />
          <h2 className="module-h2">Vi erbjuder</h2>
          <h3 className="module-h3">Individuella insatser:</h3>
          <ul className="service-list">
            <li>Kartläggning av måltidssituation och ätförmåga</li>
            <li>
              Ätträning (att lära sig att äta, eller att lära sig äta flera
              olika livsmedel)
            </li>
            <li>
              Rådgivning kring förhållningssätt och anpassningar i
              matsituationen
            </li>
          </ul>
          <h3 className="module-h3">
            Utbildning, föreläsning och handledning till föräldrar, närstående
            eller personal kring:
          </h3>
          <ul className="service-list">
            <li>Ätutveckling och ätsvårigheter</li>
            <li>Selektivt ätande</li>
            <li>Förhållningssätt och anpassningar i måltidssituationen</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

const BehaviourModule = ({ setSelectedModule }) => {
  return (
    <div>
      <button className="back" onClick={() => setSelectedModule(null)}>
        <FontAwesomeIcon icon={faArrowLeftLong} id="arrow" />
        TILLBAKA
      </button>
      <div className="module">
        <p className="module-title">BETEENDE</p>
        <FontAwesomeIcon icon={faHandHoldingHeart} id="heart" />
        <p className="main-text">
          Beteenden är, med en vid definition, egentligen allting vi människor
          gör, både medvetet och omedvetet. Ibland används begrepp som utmanande
          beteende/problemskapande beteende, vilket kan vara exempelvis sparkar,
          slag, skrik, fula ord, eller att en person spottar eller bits. Vad som
          uppfattas som problematiskt beror inte bara på situationen utan även
          på personerna runt omkring. Vad som är utmanande/problematiskt för mig
          är det kanske inte för dig. Oavsett - om man vill påverka/förändra ett
          beteende är det viktigt att förstå vad det fyller för funktion för
          personen som utför det (för någon funktion fyller det)! Det är inte
          alltid så lätt att förstå varför ett beteende uppstår, men med
          noggrann kartläggning kan vi komma framåt. Vi vill gärna vara med och
          stötta er i den processen.
          <br />
          <br />
          <h2 className="module-h2">Vi erbjuder</h2>
          <h3 className="module-h3">Individuella insatser:</h3>
          <ul className="service-list">
            <li>
              Kartläggning/observation av beteende/kommunikation/omgivning
            </li>
            <li>
              Individanpassad rådgivning kring förhållningssätt/strategier
            </li>
          </ul>
          <h3 className="module-h3">
            Utbildning, föreläsning och handledning till föräldrar, närstående
            eller personal kring:
          </h3>
          <ul className="service-list">
            <li>Hur kan vi förstå, arbeta med och förändra ett beteende?</li>
            <li>
              TBA (Tillämpad beteendeanalys), PBS (positivt beteendestöd),
              lågaffektivt bemötande, tydliggörande pedagogik
            </li>
            <li>Handledning utifrån era frågor och behov</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Services;
